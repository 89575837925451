const isObject = (obj) => {
  return Object.prototype.toString.call(obj) === "[object Object]";
};

const arrayEquals = (v1, v2) => {
  return (
    Array.isArray(v1) &&
    Array.isArray(v2) &&
    v1.length === v2.length &&
    v1.every((val, index) => checkObjectEqual(val, v2[index]))
  );
};

const objectEquals = (v1, v2) => {
  if (!isObject(v1) || !isObject(v2)) {
    return false;
  }
  for (let key in v1) {
    if (!(key in v2)) return false;
  }
  for (let key in v2) {
    if (!(key in v1)) return false;
  }
  for (let key in v1) {
    if (!checkObjectEqual(v1[key], v2[key])) {
      return false;
    }
  }
  return true;
};

export default function checkObjectEqual(v1, v2) {
  if (Array.isArray(v1)) {
    if (!arrayEquals(v1, v2)) {
      return false;
    }
  } else if (isObject(v1)) {
    if (!objectEquals(v1, v2)) {
      return false;
    }
  } else {
    if (v1 != v2) {
      return false;
    }
  }

  return true;
}
