import { useEffect, useState } from "react";

import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Divider,
  Switch,
  Icon,
} from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import { useTranslation } from "react-i18next";
import setConsent from "app/utils/consent/setConsent";
import getConsent from "app/utils/consent/getConsent";
import { BASE_URL } from "settings";

const CookieConsent = ({ setOpen }) => {
  const { t } = useTranslation();

  const [performance, setPerformace] = useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    const consent = getConsent();
    setPerformace(consent.performance);
  }, []);

  const submitConsent = () => {
    setConsent(performance);
    handleClose();
  };

  const acceptAll = () => {
    setConsent(true);
    handleClose();
  };

  const rejectAll = () => {
    setConsent(false);
    handleClose();
  };

  return (
    <Dialog
      open={true}
      fullWidth={true}
      maxWidth="md"
      onClose={handleClose}
      sx={{ backdropFilter: "blur(5px)" }}
    >
      <SuiBox p={2}>
        <DialogTitle>
          <SuiBox
            display="flex"
            justifyContent="space-between"
            alignItems="center"
          >
            <SuiBox>
              <SuiTypography variant="h5" fontWeight="bold" lineHeight={1}>
                {t("Privacy settings")}
              </SuiTypography>
            </SuiBox>
            <SuiBox onClick={handleClose} sx={{ cursor: "pointer" }}>
              <Icon>close</Icon>
            </SuiBox>
          </SuiBox>
        </DialogTitle>
        <DialogContentText px={2}>
          <SuiTypography variant="caption" lineHeight={0}>
            {t(
              "When you visit any of our websites, it may store or retrieve information on your browser, mostly in (but not limited to) the form of cookies. This information might be about you, your preferences or your device and is mostly used to make the site work as you expect it to. The information does not usually directly identify you, but it can give you a more personalized web experience. Because we respect your right to privacy, you can choose not to allow some types of cookies. Click on the different category manage your preferences. Please note, blocking some types of cookies may impact your experience of the site and the services we are able to offer."
            )}
          </SuiTypography>
          <SuiTypography variant="caption" lineHeight={0}>
            {" "}
            {t("Learn more about")}{" "}
          </SuiTypography>
          <SuiTypography
            variant="caption"
            color="dark"
            component="a"
            href={`${BASE_URL}/privacy/cookies`}
            target="_blank"
            rel="noreferrer"
            sx={{
              "&:hover": {
                color: ({ palette: { info } }) => info.main,
              },
            }}
          >
            {t("cookies policy.")}
          </SuiTypography>
        </DialogContentText>
        <DialogContent>
          <Divider />
          <SuiBox display="flex" justifyContent="space-between">
            <SuiBox>
              <SuiTypography variant="body2">
                {t("Strictly Necessary")}
              </SuiTypography>
            </SuiBox>
            <SuiBox>
              <Switch checked={true} />
            </SuiBox>
          </SuiBox>
          <Divider />
          <SuiBox display="flex" justifyContent="space-between">
            <SuiBox>
              <SuiTypography variant="body2">{t("Performance")}</SuiTypography>
            </SuiBox>
            <SuiBox>
              <Switch
                checked={performance}
                onChange={() => {
                  setPerformace(!performance);
                }}
              />
            </SuiBox>
          </SuiBox>
          <Divider />
        </DialogContent>
        <DialogContent>
          <Grid container spacing={2} justifyContent="space-between">
            <Grid
              container
              item
              xs="auto"
              direction={{ xs: "column", md: "row" }}
              spacing={2}
            >
              <Grid item>
                <SuiButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={acceptAll}
                >
                  {t("accept all")}
                </SuiButton>
              </Grid>
              <Grid item>
                <SuiButton
                  variant="outlined"
                  color="secondary"
                  size="small"
                  onClick={rejectAll}
                >
                  {t("reject all optional")}
                </SuiButton>
              </Grid>
            </Grid>
            <Grid item xs="auto">
              <SuiButton
                variant="gradient"
                color="success"
                size="small"
                onClick={submitConsent}
              >
                {t("confirm")}
              </SuiButton>
            </Grid>
          </Grid>
        </DialogContent>
      </SuiBox>
    </Dialog>
  );
};

export default CookieConsent;
