// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

import lightBulb from "assets/images/light-bulb.jpg";

import { URL_CODEXSHEET } from "settings";

const FreeTools = () => {
  const { t } = useTranslation();
  const image = lightBulb;

  return (
    <SuiBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <SuiBox
            variant="gradient"
            bgColor="info"
            borderRadius="lg"
            width="100%"
            py={6}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                sx={{ position: "relative", px: 6 }}
              >
                <SuiBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  my: { xs: 0, md: "auto" },
                  mt: { xs: 6, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <SuiTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  {t(
                    "Codex Sheet is designed to tackle specific or technical issue, such as Lease Accounting (IFRS 16), Expected Credit Loss (IFRS 9), etc."
                  )}
                </SuiTypography>{" "}
                <SuiTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  {t(
                    "Codex Sheet is an online application which you can create separate worksheet for each sheet."
                  )}
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="bold" color="white">
                  {t("Codex Lab")}
                </SuiTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{
                  px: { xs: 6, xl: 0 },
                  my: { xs: 0, xl: "auto" },
                  mt: { xs: 6, xl: "auto" },
                  lineHeight: 1,
                }}
              >
                <SuiTypography
                  component="p"
                  variant="button"
                  color="white"
                  opacity={0.8}
                  mb={2}
                >
                  {t("Codex Sheet beta is coming soon.")}
                </SuiTypography>
              </Grid>
            </Grid>
          </SuiBox>
        </Grid>
      </Container>
    </SuiBox>
  );
};

export default FreeTools;
