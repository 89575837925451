import i18next from "i18next";

import Cookies from "js-cookie";
import { BASE_COOKIE_URL } from "settings";

import { I18N_COOKIE_NAME } from "settings/i18n";

const changeLanguage = (lng = null) => {
  if (lng) {
    i18next.changeLanguage(lng);
    Cookies.set(I18N_COOKIE_NAME, lng, {
      expires: 1000,
      domain: BASE_COOKIE_URL,
    });
  }
};

export default changeLanguage;
