import StandardLayout from "layouts/standard-layout";

import SuiTypography from "components/sui/SuiTypography";
import SuiBox from "components/sui/SuiBox";

import { useTranslation } from "react-i18next";

const NotFound404 = () => {
  const { t } = useTranslation();
  return (
    <StandardLayout>
      <SuiBox
        minHeight={"100vh"}
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
      >
        <SuiTypography variant="h1" color="warning" textGradient fontSize={200}>
          404
        </SuiTypography>
        <SuiTypography variant="h1" color="warning" textGradient>
          {t("Page not found")}
        </SuiTypography>
      </SuiBox>
    </StandardLayout>
  );
};

export default NotFound404;
