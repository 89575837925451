import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import StandardLayout from "layouts/standard-layout";

import { useTranslation } from "react-i18next";

import { useEffect } from "react";

const CookiesPolicy = () => {
  const { t } = useTranslation("privacy");

  useEffect(() => {
    setTimeout(() => {
      document.title = "Cookies Policy | Codex Lab";
    }, 100);
  }, []);

  return (
    <StandardLayout>
      <SuiBox component="section" pt={15} pb={0}>
        <Container>
          <Card>
            <SuiBox
              variant="gradient"
              bgColor="dark"
              borderRadius="xl"
              coloredShadow="dark"
              p={3}
              mt={-3}
              mx={2}
            >
              <SuiBox pt={3}>
                <SuiTypography variant="h4" fontWeight="bold" color="white">
                  {t("Cookies policy")}
                </SuiTypography>
                <SuiTypography variant="caption" color="white" opacity={0.8}>
                  {t("Last modified")}:{" "}
                  {new Date(Date.parse("31 May 2022")).toLocaleDateString()}
                </SuiTypography>
              </SuiBox>
            </SuiBox>

            <SuiBox pb={6} px={6}>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("What are cookies?")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "A cookie is a small text file downloaded onto your computers or mobile telephone devices. The purpose of which is to store the various information and settings such as recording information of language setting in browser on your devices, recording your current access status so that you can access the website uninterrupted including collecting information on history of visited websites of your preference in the form of file. On this basis, a cookie does not pose a danger to your device and the content in the cookie may only be viewed or read via website which created it."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Benefit of cookies")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "A cookie will inform us which section of our website has been visited by you so that we can offer you better experience with regard to the use of website consistently with your need. In addition, using cookies to record the initial settings of the website will allow you to access the website with such initially determined settings on every occasion of use except where cookies are deleted. In the latter case, all the settings will be restored to the default settings."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Use of Cookies")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" fontWeight="regular">
                {t(
                  "We use cookies and other technologies such as local storage on our websites (collectively referred to as “Cookies Policy based Services”). Therefore, any browser used by you to access the Cookies Policy based Services will receive cookies from us."
                )}
                <br />
                <br />
                {t(
                  "When you access the Cookies Policy based Services, our cookies will be downloaded onto your device in order to collect information on usability format and website usage history, information or services of your interest as well as the reference number for your latest access etc."
                )}
                <br />
                <br />
                {t(
                  "Moreover, we use cookies in conjunction with other technologies to ascertain the usability format and website usage history, information or services of your interest, and analyze such information to develop the services, display content, advertisement or public relations of appropriate activities including services that match your interest in order to enhance your satisfaction."
                )}
                <br />
                <br />
                {t(
                  "Nonetheless, we will use cookies pursuant to the stipulations under the Privacy policy, which you can study more information at https://codexlab.co/privacy"
                )}
                <br />
                <br />
                {t(
                  "We may use plugins and third parties services such as Google Analytics."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Categories of cookies")}
              </SuiTypography>
              <SuiTypography variant="h6" mb={1}>
                {t("Strictly Necessary Cookies")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "These cookies are necessary for our website to function properly and cannot be switched off in our systems. They are usually only set in response to actions made by you which amount to a request for services, such as setting your privacy preferences, logging in or filling in forms or where they're essential to provide you with a service you have requested. You cannot opt-out of these cookies. You can set your browser to block or alert you about these cookies, but if you do, some parts of the site will not then work. These cookies do not store any personally identifiable information."
                )}
              </SuiTypography>
              <SuiTypography variant="h6" mt={3} mb={1}>
                {t("Optional cookies")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "We will ask for your permission to place the cookies listed below on your device, but if you opt out of them, some functions may not work properly. We will only use these cookies with your permission when you accept our use of the cookies. These are explained in more detail below."
                )}
              </SuiTypography>
              <SuiTypography variant="h6" mt={3} mb={1}>
                {t("Performance Cookies")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "These cookies allow us to count visits and traffic sources so we can measure and improve the performance of our site. They help us to know which pages are the most and least popular and see how visitors move around the site, which helps us optimize your experience. All information these cookies collect is aggregated and therefore anonymous. If you do not allow these cookies we will not be able to use your data in this way."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Cookie Disabling Method")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text">
                {t(
                  "You can disable the cookies through your browser and privacy settings in order to prevent the cookies from collecting information in the future (more information may be studied from AboutCookies.org)."
                )}
                <br />
                <br />
                {t(
                  "However, certain services on our websites require the use of cookies, and the disabling of cookies may affect certain or all the functions of these services thereby affecting the smoothness of operation."
                )}
              </SuiTypography>
            </SuiBox>
          </Card>
        </Container>
      </SuiBox>
    </StandardLayout>
  );
};

export default CookiesPolicy;
