import Container from "@mui/material/Container";
import Card from "@mui/material/Card";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import StandardLayout from "layouts/standard-layout";

import { useTranslation } from "react-i18next";

import { BASE_URL } from "settings";

import { useEffect } from "react";

const PrivacyPolicy = () => {
  const { t } = useTranslation("privacy");

  useEffect(() => {
    setTimeout(() => {
      document.title = "Privacy Policy | Codex Lab";
    }, 100);
  }, []);

  return (
    <StandardLayout>
      <SuiBox component="section" pt={15} pb={0}>
        <Container>
          <Card>
            <SuiBox
              variant="gradient"
              bgColor="info"
              borderRadius="xl"
              coloredShadow="dark"
              p={3}
              mt={-3}
              mx={2}
            >
              <SuiBox pt={3}>
                <SuiTypography variant="h4" fontWeight="bold" color="white">
                  {t("Privacy policy")}
                </SuiTypography>
                <SuiTypography variant="caption" color="white" opacity={0.8}>
                  {t("Last modified")}:{" "}
                  {new Date(Date.parse("31 May 2022")).toLocaleDateString()}
                </SuiTypography>
              </SuiBox>
            </SuiBox>

            <SuiBox pb={6} px={6}>
              <SuiTypography variant="body2" color="text" mt={6}>
                {t(
                  "This Privacy policy is provided to clarify the details and methods on how we, Codex Lab Co., Ltd. (“Codex”), handle the information that we received from you, the purposes for the collection, use, disclosure, and processing, including the retention period of such Personal Data, and your rights as the data subject. As we conduct business in Thailand, the processing of your Personal Data shall therefore fall under the compliance of and is required to comply with Thailand's Personal Data Protection Laws accordingly."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("The methods to collect and receive your Personal Data")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "1. Codex collects and receives your Personal Data via the following methods"
                )}
                :
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) Personal Data which you directly provide to Codex: You may directly provide your Personal Data to Codex. This case usually occurs when you contact Codex to make enquiries, complete forms either by online or by document, apply for services or join activities, request for information, receive marketing communications, or provide comments or feedback to Codex, etc."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) Personal Data which Codex automatically collect from you: Codex may automatically collect some technical information, in relation to your devices, activities and visiting patterns and browsing history, by using Cookies and other similar technologies. For more details, please see "
                  )}
                  <SuiTypography
                    variant="body2"
                    color="text"
                    component="a"
                    href={`${BASE_URL}/privacy/cookies`}
                    target="_blank"
                    rel="noreferrer"
                    sx={{
                      "&:hover": {
                        color: ({ palette: { info } }) => info.main,
                      },
                    }}
                  >
                    {t("Cookies policy.")}
                  </SuiTypography>
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) Personal Data which Codex receives from third parties: Codex may, from time to time, receive your Personal Data from third parties, such as the banks, and government organizations."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "2. In collecting your Personal Data, you will be informed of details set out in this Privacy policy, or in the event that the Personal Data Protection Laws require your consent to be provided, Codex will request for your explicit consent in order to process your Personal Data."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Collected Personal Data")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "1. Personal Data which Codex collects and processes under this Privacy policy is the Personal Data of the following categories of data subject"
                )}
                :
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) Customers, i.e., website visitors, service recipients, and other persons who contact Codex to request information or services from Codex, regardless of whether such Personal Data was directly or indirectly received."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) Contracting Parties, Professionals and other persons relating to the business of Codex, i.e., natural persons who are contracting parties or contractually related with Codex, including business partners, sellers, suppliers, service providers, contractors, advisers, investment professionals, and other similar persons."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) Personnel of Codex, i.e., natural persons who are staff, employees or persons who work for Codex, directors, managers, executives, professionals, and persons who directly receive monthly salaries, wages, benefits, or any other types of compensation from Codex, including the family members of such personnel of Codex."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(d) Candidates, i.e., natural persons who submit an application or details relating to their profile to Codex, either in the form of written or verbal, with the purpose to apply for the job, internship/scholarship as staff or employees/interns/scholarship applicants of Codex who are not yet chosen by Codex, including the family members of such candidates and references."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "2. Your Personal Data which is collected and processed under this Privacy policy, regardless of whether such Personal Data was directly provided by you to Codex or automatically collected from you by Codex, or was provided to Codex by third parties, such as"
                )}
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) Personal Data, such as first name, last name, date/month/year of birth, age, gender, identification number and copy of passport or ID card, signature, nationality, marital status, and family members information."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) Sensitive Personal Data, such as religion, criminal record, health data, health examination result, disability, and biometric data."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) Contact details, such as address, telephone number, e-mail, social media contact details, and details of emergency contacts."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(d) Financial information, such as bank account number, and information regarding various types of tax."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(e) Information regarding Securities/Derivatives, such as securities and derivatives trading account number, interest or dividend information, securities deposit-withdrawal information, securities transfer or changing of securities holders, and securities pledging information."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(f) Information regarding the use of systems of Codex, such as information regarding the registration or subscription for using Codex's systems, user account, account profile, username, password, and PIN (if any), information showing on account profile page and services applying page, information in your account profile that edited or amended by you, information received from other accounts that Codex has a reasonable cause to believe that you are controlling such other accounts, service usage information, all interests and all comments that you express through systems of Codex (if any), your activities participation information in systems of Codex, survey information, information received from your communication with Codex or Codex's working team."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(g) Technical information, such as the information of access to Codex's websites and systems, computer traffic data (Log), contact and communication information between you and other users, information from using records e.g., device indicator, computer IP address, device identification number, type of device, mobile phone network information, connecting information, geological information, browser category, system access record, information of referring application or website, use of system record, login log information, transaction log information, customer behavior information, system access statistics, access time, your searching information, the use of functions in systems, and information that Codex collects through the use of Cookies or other similar technologies."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(h) Academic, Training, and Work Experience information, such as academic and training background, academic certificate or transcript, result or raking of points, level of education, language skills, professional licenses information, registration number, registration starting date, registration expiration date, registration renewal information, duty performance information, work starting date, information of training and test provided by Codex or other relevant organizations, diploma, certificate, work experiences, monthly salaries or wages."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(i) Other information, such as voice recording of conversations and your uploaded file."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Use of Personal Data")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "1. Codex use the information from and about User for your benefits in using the Codex's Services that meet User's purposes and for other purposes necessary under laws"
                )}
                :
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) To provide and operate the Services and to fulfill our contractual obligation with you."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) To respond to User requests, communicate with you, to provide customer service and technical support."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) To verify User identity and prevent spam or other unauthorized or illegal activity."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(d) To prevent, detect, mitigate, and investigate fraud, security breaches or other potentially prohibited or illegal activities"
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(e) As necessary to meet responsibilities Codex have to our regulators, tax officials, law enforcement, or otherwise meet our legal obligations."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(f) As necessary for our legitimate interests or legitimate interests of any other person or legal person."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(g) For preventing or suppressing a danger to a person's life, body or health."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(h) As necessary for the performance of a task Codex carried out in the public interest, or it is necessary for the exercising of official authority vested in us."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(i) For the achievement of the purpose relating to the preparation of the historical documents or the archives for public interest, or for the purpose relating to research or statistics with appropriate security measure."
                  )}
                </SuiTypography>
              </SuiBox>

              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "2. Codex use the information to enable User to receive benefits from using Services according to User's given consent"
                )}
                :
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) To better understand how users access and use our Services, in order to improve our Services and respond to user desires and preferences, and/or for other research and analytical purposes."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) To offer you the products and promotions from Codex and/or from our affiliates and business partners."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) To analyze, deliver targeted marketing, targeted advertising, service updates, and promotional offers based on User communication preferences and to improve and keep our product up to date with the latest technology."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "If you do not provide User information to us, Codex may not be able to facilitate, communicate or provide some or all of our Services to you."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Disclosure of Personal Data")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "1. Codex may disclose your Personal Data under the Specified Purposes and in accordance with the laws and regulations to the following entities and persons"
                )}
                :
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(a) Codex Lab Co., Ltd., including the staff, employees, directors, managers or personnel of Codex as deemed relevant and necessary (on a need-to-know basis), for the processing of your Personal Data;"
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(b) The organizations requesting for the disclosure of information by virtue of the laws, such as requesting information for the prosecution or legal prosecution, or relating to the legal proceedings, for example, Anti-Money Laundering Office, the National Anti-Corruption Commission, Royal Thai Police, Department of Special Investigation, Office of the Attorney General, and the courts;"
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "(c) Alliances, business partners, service providers, service recipients and Data Processors assigned by Codex to be responsible of providing services or managing of Personal Data, such as improving or maintaining the security standard of the systems and the technology system, payment systems, audit, human resource management or other services which may be beneficial to you."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "2. The disclosure of your Personal Data to third parties shall be in accordance with the Specified Purposes or other purposes permitted by laws, provided that if the law requires your consent, Codex will request for your explicit consent in advance."
                )}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "3. In the event that Codex discloses your Personal Data to third parties, Codex will put in place appropriate safeguards to protect the Personal Data that has been disclosed and to comply with the standards and duties relating to the protection of Personal Data as prescribed by the Personal Data Protection Laws."
                )}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "4. In the event that Codex sends or transfers your Personal Data outside Thailand, Codex will ensure that the recipient country, the international organization or such overseas recipient has a sufficient standard for the protection of Personal Data. In some cases, Codex may request your consent for the transfer of your Personal Data outside Thailand, subject to the requirements under the Personal Data Protection Laws."
                )}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "5. As Codex may be required to disclose your Personal Data to other regulating authorities, e.g., Anti-Money Laundering Office etc., if you wish to know more details regarding the processing of your Personal Data carried out by such regulating authorities, please refer directly to the Privacy policy of such relevant regulating authorities."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Retention Period")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "1. Codex will retain your Personal Data for the period necessary to fulfil the Specified Purposes for which the Personal Data was processed, whereby the retention period may be changed depending on the Specified Purposes for which such Personal Data was collected and processed."
                )}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "2. Codex will also retain Personal Data for the period prescribed under the applicable laws, having regard to the business practices in relation to each category of Personal Data. After the aforementioned retention period, Codex will delete such Personal Data from the storage or systems of Codex without prior notice to you."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Rights of Data Subject")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "As the data subject, you have the following rights in relation to your Personal Data, subject to the rules, methods and conditions under the Personal Data Protection Laws. In this regard, if you wish to make a request to exercise your rights, you can contact Codex by using the contact details set out below of this Privacy policy."
                )}
              </SuiTypography>
              <SuiBox pl={"4rem"}>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("1. Right to Access")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You have the right to access your Personal Data and may request Codex to provide you with a copy of such Personal Data in accordance with the requirements under the Personal Data Protection Laws, including to disclose how the Codex receive your Personal Data, which is collected, used and disclosed by the Codex without requiring of your consent, to the extent permitted by the Personal Data Protection Laws."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("2. Right to Data Portability")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You have the right to obtain your Personal Data in the format which is readable or commonly used by ways of automatic tools or equipment, including to request to send or transfer your Personal Data to another Data Controller or to you, unless it is technically unfeasible to do so. Your rights to data portability shall be in accordance with the requirements under the Personal Data Protection Laws."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("3. Right to Object")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You have the right to raise an objection to the processing of your Personal Data, and Codex will conduct so in case that the Codex;"
                  )}
                </SuiTypography>
                <SuiBox pl={"4rem"}>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(a) processes your Personal Data based on our or third parties' legitimate interest or public interest, except in the event that the Codex can demonstrate compelling legitimate grounds, or the processing of your Personal Data is carried out for establishment, compliance with or exercise of the legal claims or defense of the legal claims;"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(b) processes your Personal Data for the purpose of direct marketing; or"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(c) processes your Personal Data for the purpose of scientific, historic or statistic research, unless it is necessary for conducting activities for the public interest of Codex."
                    )}
                  </SuiTypography>
                </SuiBox>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "In the event that you object to the processing of Personal Data, please kindly inform us whether you wish to delete your Personal Data or to restrain Codex from processing such Personal Data."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("4. Right to Erasure")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You may request Codex to delete, destroy or anonymize your Personal Data in certain circumstances as follows:"
                  )}
                </SuiTypography>
                <SuiBox pl={"4rem"}>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(a) There is no further necessity to retain your Personal Data according to the Specified Purpose for processing your Personal Data;"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(b) You withdraw your consent which is the basis of collecting and processing the Personal Data, and Codex has no legal ground for collecting or processing such Personal Data;"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(c) You object to the processing as mentioned in Clause 3; or"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(d) The collecting or processing of your Personal Data is unlawful."
                    )}
                  </SuiTypography>
                </SuiBox>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "The abovementioned cases shall not apply to the processing that is necessary for the purpose of freedom of expression, preparing of the historic or statistic documents, performing a task carried out in the public interest, complying with the law for achieving purposes with respect to preventive medicine or occupational medicine or public interest in public health, establishment, compliance with or exercise of the legal claims or defense of the legal claims or compliance with the law."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("5. Right to Restriction")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You have the right to request a restriction to the processing of your Personal Data in certain circumstances as follows:"
                  )}
                </SuiTypography>
                <SuiBox pl={"4rem"}>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(a) Codex is pending the verification of the accuracy of Personal Data as per your request;"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(b) In case of Personal Data which shall be deleted or erased according to Clause 4 that you request a restriction to the use instead;"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(c) Codex has no further necessary to use your Personal Data, however, you have a necessity and therefore request Codex to retain such Personal Data, for establishment, compliance, or exercise of the legal claims, or for defense of the legal claims; or"
                    )}
                  </SuiTypography>
                  <SuiTypography variant="body2" color="text" mb={1}>
                    {t(
                      "(d) Codex is pending the verification according to Clause 3 (a) or Clause 3 (c) in order to reject your objection request pursuant."
                    )}
                  </SuiTypography>
                </SuiBox>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("6. Right to Rectification")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "You have the right to request that your Personal Data be rectified if the Personal Data is inaccurate, not up-to-date or incomplete, or may cause a misunderstanding."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("7. Right to Withdraw Consent")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "In the event that Codex relies on your consent as the legal basis for processing your Personal Data, you have the right to withdraw such consent which has been provided to the Codex at any time that such Personal Data is in the possession of the Codex."
                  )}
                </SuiTypography>
                <SuiTypography variant="h6" mt={3} mb={1}>
                  {t("8. Right to Lodge a Complaint")}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "If you have any concerns or questions about any aspect of Codex's practices in relation to the processing of your Personal Data, please contact Codex using the contact details set out below. In case of an alleged infringement of Personal Data Protection Laws, you have the right to lodge a complaint to the Office of Personal Data Protection Committee."
                  )}
                </SuiTypography>
                <SuiTypography variant="body2" color="text" mb={1}>
                  {t(
                    "Codex will apply our best effort of the capability of the relevant systems to facilitate and carried out at your request without delay, unless it appears that to carry out at such request causes undue burden on or would involve excessive or disproportionate effort of Codex or is in the risk of violating of the protection of others' Personal Data or illegal or impossible to perform such request."
                  )}
                </SuiTypography>
              </SuiBox>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Security for the Retention of Personal Data")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "Codex have in place and/or adopt the Personal Data storage systems, which has appropriate mechanism and technique and security standard according to the Personal Data Protection Laws and the relevant regulations, including the limitation of access of your Personal Data by staff, employees and representatives of Codex for the purpose of preventing your Personal Data from the unauthorized usage, disclosure, erasure or access."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Link to websites, products and services of third parties")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "Provision of services of Codex may link to websites, applications, products and services of third parties, which such third parties may collect certain information in relation to the use of such service. This Privacy policy is solely applied to the processing of Personal Data under the purpose specified by Codex. Codex could not be responsible for the security or privacy or any of your information collected by websites, applications, products and services of such third parties, even you click the link appeared on the service of Codex. In this regard, you should be careful and verify the Privacy policy or privacy policy of websites, applications, products and services of such third parties."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("How to contact Codex")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "If you have any questions in relation to this Privacy policy, or would like to exercise your rights as set out in this Privacy policy, please kindly contact Codex through the Data Protection Officer of Codex by using the following details;"
                )}
                <br />
                {t("E-mail")}: dpo@codexlab.co
                <br />
                {t("Telephone Number")}: +6697-983-9000
                <br />
                {t("Mail to the Data Protection Officer")}
                {": "}
                {t("836 Ekkachai Road, North Bangbon, Bangbon, Bangkok 10150")}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Applicable Law")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "This Privacy policy shall be governed by and construed in accordance with Thai laws, and Thai courts shall have jurisdiction over any dispute that may arise out of or relate to this Privacy policy."
                )}
              </SuiTypography>
              <SuiTypography variant="h5" mt={6} mb={3}>
                {t("Changes to this Privacy policy")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t(
                  "Codex may make changes to this Privacy policy from time to time, in order to reflect any changes to our processing of your Personal Data, and to comply with changes to the Personal Data Protection Laws or any applicable laws. In this regard, Codex will notify you of any significant changes to the Privacy policy through appropriate means. Nevertheless, Codex encourages you to review the Privacy policy for any changes from time to time."
                )}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t("This Privacy policy shall be effective from 31 May 2022")}
              </SuiTypography>
              {/* <SuiTypography variant="h5" mt={6} mb={3}>
                {t("")}
              </SuiTypography>
              <SuiTypography variant="body2" color="text" mb={1}>
                {t("")}
              </SuiTypography> */}
            </SuiBox>
          </Card>
        </Container>
      </SuiBox>
    </StandardLayout>
  );
};

export default PrivacyPolicy;
