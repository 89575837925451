/*
=========================================================
* Material Kit 2 PRO React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

function DefaultFooter({ content }) {
  const { brand, socials, menus, copyright } = content;

  const { t } = useTranslation();

  return (
    <SuiBox component="footer" sx={{ userSelect: "none"   }}>
      <Container>
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} md={3}>
            <SuiBox>
              <Link to={brand.route}>
                <SuiBox
                  component="img"
                  src={brand.image}
                  alt={brand.name}
                  maxWidth="2rem"
                  mb={2}
                />
              </Link>
              <SuiTypography variant="h6">{brand.name}</SuiTypography>
            </SuiBox>
            <SuiBox display="flex" alignItems="center" mt={3}>
              {socials.map(({ icon, link }, key) => (
                <SuiTypography
                  key={link}
                  component="a"
                  href={link}
                  target="_blank"
                  rel="noreferrer"
                  variant="h5"
                  color="dark"
                  opacity={0.8}
                  mr={key === socials.length - 1 ? 0 : 2.5}
                >
                  {icon}
                </SuiTypography>
              ))}
            </SuiBox>
          </Grid>
          <Grid container item xs={12} md={9} justifyContent="flex-end">
            {menus.map(({ name: title, items }) => (
              <Grid
                key={title}
                item
                xs={12}
                sm={6}
                md={4}
                lg={3}
                sx={{ mb: 3 }}
              >
                <SuiTypography
                  display="block"
                  variant="button"
                  fontWeight="bold"
                  textTransform="capitalize"
                  mb={1}
                >
                  {t(title)}
                </SuiTypography>
                <SuiBox component="ul" p={0} m={0} sx={{ listStyle: "none" }}>
                  {items.map(({ name, route, href }) => (
                    <SuiBox
                      key={name}
                      component="li"
                      p={0}
                      m={0}
                      lineHeight={1.25}
                    >
                      {href ? (
                        <SuiTypography
                          component="a"
                          href={href}
                          target="_blank"
                          rel="noreferrer"
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {t(name)}
                        </SuiTypography>
                      ) : (
                        <SuiTypography
                          component={Link}
                          to={route}
                          variant="button"
                          fontWeight="regular"
                          textTransform="capitalize"
                        >
                          {t(name)}
                        </SuiTypography>
                      )}
                    </SuiBox>
                  ))}
                </SuiBox>
              </Grid>
            ))}
          </Grid>
          <Grid item xs={12} sx={{ textAlign: "center", my: 3 }}>
            {copyright}
          </Grid>
        </Grid>
      </Container>
    </SuiBox>
  );
}

// Typechecking props for the DefaultFooter
DefaultFooter.propTypes = {
  content: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.object, PropTypes.array])
  ).isRequired,
};

export default DefaultFooter;
