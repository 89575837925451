import { Fragment } from "react";

import SuiBox from "components/sui/SuiBox";

import DefaultNavbar from "components/cdx/CdxNavbars/DefaultNavbar";
import DefaultFooter from "components/cdx/CdxFooters/DefaultFooter";

import { navbarRoutes } from "routes";
import footerRoutes from "footer.routes";

const StandardLayout = ({ children }) => {
  return (
    <Fragment>
      <DefaultNavbar routes={navbarRoutes} sticky />
      {children}
      <SuiBox pt={6} px={1} mt={6}>
        <DefaultFooter content={footerRoutes} />
      </SuiBox>
    </Fragment>
  );
};

export default StandardLayout;
