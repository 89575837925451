//https://datayolk.net/

import { useState, useEffect, Fragment } from "react";
import { Grid, Container, Icon } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

import rgba from "assets/theme/functions/rgba";

import { useTranslation } from "react-i18next";

import { NavLink, useLocation } from "react-router-dom";

import { PATH_TO_SKIP_PRIVACY_CONSENT } from "settings";
import CdxChangeLanguage from "components/cdx/CdxChangeLanguage";
import CookieConsent from "layouts/privacy-consent/cookie-consent";
import getConsent from "app/utils/consent/getConsent";
import setConsent from "app/utils/consent/setConsent";

const PrivacyConsent = () => {
  const { t } = useTranslation();
  const { pathname } = useLocation();
  const [show, setShow] = useState(true);
  const [openConsentDialog, setOpenConsentDialog] = useState(false);

  useEffect(() => {
    const isStartsWithSkipPath =
      PATH_TO_SKIP_PRIVACY_CONSENT.filter((e) => pathname.startsWith(e))
        .length > 0;
    if (isStartsWithSkipPath) {
      setShow(false);
    } else {
      const consent = getConsent();
      if (consent.necessary) {
        setShow(false);
      } else {
        setShow(!openConsentDialog);
      }
    }
  }, [pathname, openConsentDialog]);

  const acceptAllConsent = () => {
    setConsent(true);
    setShow(false);
  };

  const openPrivacyConsent = () => {
    setOpenConsentDialog(true);
  };

  return (
    <Fragment>
      {openConsentDialog && <CookieConsent setOpen={setOpenConsentDialog} />}
      {show ? (
        <Fragment>
          <SuiBox
            bgColor={rgba("#000000", 0.7)}
            sx={{
              height: "100vh",
              width: "100vw",
              position: "sticky",
              bottom: 0,
              zIndex: 20,
            }}
          />
          <SuiBox
            bgColor="dark"
            sx={{
              position: "sticky",
              bottom: 0,
              zIndex: 20,
            }}
          >
            <Container>
              <SuiBox minHeight="20vh" py={4}>
                <Grid container alignItems="center" spacing={3}>
                  <Grid item xs={12} md>
                    <SuiBox lineHeight={1}>
                      <SuiTypography color="white" variant="h5" lineHeight={3}>
                        {t("We value your privacy")}
                      </SuiTypography>
                      <SuiBox component="p" py={0.5}>
                        <SuiTypography color="white" variant="caption">
                          {t(
                            "We store and/or access information on a device, such as cookies and process personal data, such as unique identifiers and standard information sent by a device to develop and improve our products."
                          )}
                        </SuiTypography>
                      </SuiBox>
                      <SuiBox component="p" py={0.5}>
                        <SuiTypography color="white" variant="caption">
                          {t("Learn more about")}{" "}
                        </SuiTypography>
                        <NavLink to="/privacy">
                          <SuiTypography
                            color="white"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {t("privacy policy")}
                          </SuiTypography>
                        </NavLink>
                        <SuiTypography color="white" variant="caption">
                          {" "}
                          {t("and")}{" "}
                        </SuiTypography>
                        <NavLink to="/privacy/cookies">
                          <SuiTypography
                            color="white"
                            variant="caption"
                            fontWeight="bold"
                          >
                            {t("cookies policy")}
                          </SuiTypography>
                        </NavLink>
                      </SuiBox>
                    </SuiBox>
                  </Grid>
                  <Grid item xs={12} md="auto">
                    <SuiBox>
                      <Grid
                        container
                        direction={{ xs: "row", md: "column" }}
                        spacing={2}
                        justifyContent="space-between"
                        alignItems={{ xs: "center", md: "stretch" }}
                      >
                        <Grid item md={12}>
                          <SuiButton
                            variant="gradient"
                            color="warning"
                            onClick={acceptAllConsent}
                            fullWidth
                          >
                            {t("I understand and accept all cookies")}
                          </SuiButton>
                        </Grid>
                        <Grid item md={12}>
                          <SuiButton
                            variant="outlined"
                            color="light"
                            fullWidth
                            onClick={openPrivacyConsent}
                          >
                            {t("Customised settings")}
                          </SuiButton>
                        </Grid>
                        <Grid
                          container
                          item
                          md={12}
                          justifyContent={{ xs: "flex-start", md: "flex-end" }}
                        >
                          <Grid item>
                            <CdxChangeLanguage color="white" />
                          </Grid>
                        </Grid>
                      </Grid>
                    </SuiBox>
                  </Grid>
                </Grid>
              </SuiBox>
            </Container>
          </SuiBox>
        </Fragment>
      ) : (
        <Fragment>
          <SuiBox
            sx={{
              position: "fixed",
              right: "1vh",
              bottom: "1vh",
              zIndex: 20,
            }}
          >
            <SuiButton
              variant="gradient"
              color="secondary"
              iconOnly
              circular
              size="large"
              shadow="lg"
              onClick={openPrivacyConsent}
            >
              <Icon>cookie</Icon>
            </SuiButton>
          </SuiBox>
        </Fragment>
      )}
    </Fragment>
  );
};

export default PrivacyConsent;
