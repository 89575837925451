// @mui material components
import Icon from "@mui/material/Icon";

// Pages
import Home from "pages/home";
import PrivacyPolicy from "pages/legal/privacy-policy";
import CookiesPolicy from "pages/legal/cookies-policy";

import { URL_CODEXSHEET, URL_FREETOOLS } from "settings";

export const navbarRoutes = [
  {
    name: "online application",
    key: "/online-application",
    icon: <Icon>computer</Icon>,
    collapse: [
      {
        name: "freetools",
        description: "Reduce time for repeated office works",
        href: URL_FREETOOLS,
      },
      // {
      //   name: "sheet (beta)",
      //   description: "Create worksheet to handle specific or techincal issue",
      //   href: URL_CODEXSHEET,
      // },
    ],
  },
];

export const normalRoutes = [
  { key: "/home", path: "/", element: <Home /> },
  {
    key: "/privacy",
    path: "/privacy",
    element: <PrivacyPolicy />,
  },
  {
    key: "/privacy/cookies",
    path: "/privacy/cookies",
    element: <CookiesPolicy />,
  },
];
