// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import { useTranslation } from "react-i18next";

import computer from "assets/images/computer.jpg";

import { URL_FREETOOLS } from "settings";

const FreeTools = () => {
  const { t } = useTranslation();
  const image = computer;

  return (
    <SuiBox component="section" position="relative" py={6}>
      <Container sx={{ maxWidth: "100% !important" }}>
        <Grid container item xs={10} sx={{ mx: "auto" }}>
          <SuiBox
            variant="gradient"
            bgColor="primary"
            borderRadius="lg"
            width="100%"
            py={6}
          >
            <Grid container>
              <Grid
                item
                xs={12}
                md={6}
                xl={4}
                sx={{ position: "relative", px: 6 }}
              >
                <SuiBox
                  component="img"
                  src={image}
                  alt="image"
                  borderRadius="md"
                  maxWidth="300px"
                  width="100%"
                  position="relative"
                  mt={-12}
                />
              </Grid>
              <Grid
                item
                xs={12}
                md={5}
                xl={4}
                sx={{
                  position: "relative",
                  px: { xs: 6, md: 2 },
                  my: { xs: 0, md: "auto" },
                  mt: { xs: 6, md: "auto" },
                  color: ({ palette: { white } }) => white.main,
                }}
              >
                <Icon fontSize="large">format_quote</Icon>
                <SuiTypography
                  variant="body2"
                  color="white"
                  fontWeight="light"
                  mb={2}
                  sx={{ fontSize: "1.125rem" }}
                >
                  {t(
                    "We develop Codex Freetools to assist everyone to handle repeated office works, especially for finance and accounting officer."
                  )}
                </SuiTypography>
                <SuiTypography variant="button" fontWeight="bold" color="white">
                  {t("Codex Lab")}
                </SuiTypography>
              </Grid>
              <Grid item xs={1} />
              <Grid
                item
                xs={12}
                xl={2}
                sx={{
                  px: { xs: 6, xl: 0 },
                  my: { xs: 0, xl: "auto" },
                  mt: { xs: 6, xl: "auto" },
                  lineHeight: 1,
                }}
              >
                <SuiTypography
                  component="p"
                  variant="button"
                  color="white"
                  opacity={0.8}
                  mb={2}
                >
                  {t("Using Codex Freetools without any cost.")}
                </SuiTypography>
                <SuiTypography
                  component="a"
                  href={`${URL_FREETOOLS}`}
                  target="_blank"
                  variant="button"
                  color="white"
                  fontWeight="regular"
                  sx={{
                    width: "max-content",
                    display: "flex",
                    alignItems: "center",

                    "& .material-icons-round": {
                      fontSize: "1.125rem",
                      transform: `translateX(3px)`,
                      transition:
                        "transform 0.2s cubic-bezier(0.34, 1.61, 0.7, 1.3)",
                    },

                    "&:hover .material-icons-round, &:focus .material-icons-round":
                      {
                        transform: `translateX(6px)`,
                      },
                  }}
                >
                  {t("Start Codex Freetools now")}
                  <Icon sx={{ fontWeight: "bold" }}>arrow_forward</Icon>
                </SuiTypography>
              </Grid>
            </Grid>
          </SuiBox>
        </Grid>
      </Container>
    </SuiBox>
  );
};

export default FreeTools;
