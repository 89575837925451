import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import { Container } from "@mui/system";

const OurExperiences = () => {
  const { t } = useTranslation();

  return (
    <SuiBox component="section" mb={0}>
      <SuiBox
        alignItems="center"
        mb={5}
        sx={{ textAlign: "center", mx: "auto" }}
      >
        <SuiTypography variant="h2" fontWeight="bold" mb={1}>
          {t("Our experiences")}
        </SuiTypography>
        <SuiTypography variant="body2" color="text">
          {t(
            "We have widely experiences serving from Thai Local Company to Thai Listed Company"
          )}
        </SuiTypography>
      </SuiBox>
      <Container>
        <Grid container columnSpacing={3} rowSpacing={3} alignItems="stretch">
          <Grid item xs={12} md={6} lg={4}>
            <SuiBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              width="100%"
              height="100%"
              p={3}
            >
              <SuiTypography
                variant="body2"
                color="white"
                fontWeight="light"
                sx={{ fontSize: "1rem" }}
              >
                {t(
                  "Integration of new functions into an accounting system to reduce the chance of human error for local business."
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SuiBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              width="100%"
              height="100%"
              p={3}
            >
              <SuiTypography
                variant="body2"
                color="white"
                fontWeight="light"
                sx={{ fontSize: "1rem" }}
              >
                {t(
                  "Developing customised application to support the accounting requirements during the transformation stage before IPO."
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SuiBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              width="100%"
              height="100%"
              p={3}
            >
              <SuiTypography
                variant="body2"
                color="white"
                fontWeight="light"
                sx={{ fontSize: "1rem" }}
              >
                {t(
                  "Providing the recommendation on hedge accounting topics for listed companies."
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SuiBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              width="100%"
              height="100%"
              p={3}
            >
              <SuiTypography
                variant="body2"
                color="white"
                fontWeight="light"
                sx={{ fontSize: "1rem" }}
              >
                {t(
                  "Providing the recommendation on tax topics for a foreign company."
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
          <Grid item xs={12} md={6} lg={4}>
            <SuiBox
              variant="gradient"
              bgColor="secondary"
              borderRadius="lg"
              width="100%"
              height="100%"
              p={3}
            >
              <SuiTypography
                variant="body2"
                color="white"
                fontWeight="light"
                sx={{ fontSize: "1rem" }}
              >
                {t(
                  "Conducting the training courses relating to accounting standards for accounting firms."
                )}
              </SuiTypography>
            </SuiBox>
          </Grid>
        </Grid>
      </Container>
    </SuiBox>
  );
};

export default OurExperiences;
