import { Route } from "react-router-dom";

export default function getRoutes(allRoutes) {
  return allRoutes.map((route) => {
    if (route.collapse) {
      return getRoutes(route.collapse);
    }
    if (route.path) {
      return (
        <Route
          exact
          path={route.path}
          element={route.element}
          key={route.key}
        />
      );
    }
    return null;
  });
}
