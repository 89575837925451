import { useTranslation } from "react-i18next";

import Grid from "@mui/material/Grid";

import FreeTools from "pages/home/sections/online-application/FreeTools";
import CodexSheet from "pages/home/sections/online-application/CodexSheetTemp"; //Change when deploy

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

const OnlineApplication = () => {
  const { t } = useTranslation();

  return (
    <SuiBox component="section" mb={10}>
      <SuiBox
        alignItems="center"
        mb={10}
        sx={{ textAlign: "center", mx: "auto" }}
      >
        <SuiTypography variant="h2" fontWeight="bold" mb={1}>
          {t("Online application")}
        </SuiTypography>
        <SuiTypography variant="body2" color="text">
          {t(
            "We develop various types of online applications to support your work."
          )}
        </SuiTypography>
      </SuiBox>
      <Grid container rowSpacing={10}>
        <Grid item xs={12}>
          <FreeTools />
        </Grid>
        <Grid item xs={12}>
          <CodexSheet />
        </Grid>
      </Grid>
    </SuiBox>
  );
};

export default OnlineApplication;
