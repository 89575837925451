import SuiTypography from "components/sui/SuiTypography";

import logoCodex from "assets/images/codex-only-logo.svg";

import { BASE_URL, URL_FREETOOLS, URL_CODEXSHEET } from "settings";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Codex Lab Co., Ltd.",
    image: logoCodex,
    route: "/",
  },
  socials: [],
  menus: [
    {
      name: "online application",
      items: [
        {
          name: "codex freetools",
          href: URL_FREETOOLS,
        },
        // {
        //   name: "codex sheet (beta)",
        //   href: URL_CODEXSHEET,
        // },
      ],
    },
    {
      name: "privacy",
      items: [
        {
          name: "privacy policy",
          href: `${BASE_URL}/privacy`,
        },
        {
          name: "cookies policy",
          href: `${BASE_URL}/privacy/cookies`,
        },
      ],
    },
  ],
  copyright: (
    <SuiTypography variant="button" fontWeight="regular">
      &copy; {date}{" "}
      <SuiTypography
        component="a"
        href={`${BASE_URL}`}
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Codex Lab Co., Ltd.
      </SuiTypography>
      , a Thai limited liability company. All rights reserved.
    </SuiTypography>
  ),
};
