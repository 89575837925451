import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import HttpApi from "i18next-http-backend";

import { I18N_COOKIE_NAME } from "settings/i18n";

const detectionOption = {
  order: ["cookie"],
  lookupCookie: I18N_COOKIE_NAME,
};

const backendOption = {
  loadPath: "/assets/locales/{{lng}}/{{ns}}.json",
};

i18n
  .use(HttpApi)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    supportedLngs: ["en", "th"],
    fallbackLng: "en",

    detection: detectionOption,
    backend: backendOption,
    ns: ["translation"],
    defaultNS: "translation",

    interpolation: {
      escapeValue: false,
    },

    react: { useSuspense: false },
  });

export default i18n;
