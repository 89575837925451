import { COOKIE_PRIVACY_CONSENT } from "settings/cookies";
import { BASE_COOKIE_URL } from "settings";

import Cookies from "js-cookie";

import { GA_TRACKING_ID } from "settings/ga";

import gtag from "app/ga";

import { PERFORMANCE_COOKIES } from "settings/cookies";

const setConsent = (performance, necessary = true) => {
  let consent = { necessary: necessary, performance: performance };
  Cookies.set(COOKIE_PRIVACY_CONSENT, JSON.stringify(consent), {
    domain: BASE_COOKIE_URL,
    expires: 365,
  });

  const googleTag = document.getElementById("google-tag");

  if (performance) {
    if (!googleTag) {
      const script = document.createElement("script");
      script.setAttribute("id", "google-tag");
      script.async = true;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
      document.head.appendChild(script);
      gtag("js", new Date());
      gtag("config", GA_TRACKING_ID);
    }
  } else {
    if (googleTag) {
      googleTag.remove();
    }
    const cookies = Cookies.get();
    Object.entries(cookies).forEach(([k, v]) => {
      PERFORMANCE_COOKIES.forEach((e) => {
        if (k.startsWith(e)) {
          Cookies.remove(k);
        }
      });
    });
  }
  return consent;
};

export default setConsent;
