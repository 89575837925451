import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import CdxSimpleInfoCard from "components/cdx/CdxSimpleInfoCard";
import { useTranslation } from "react-i18next";

const OurServices = () => {
  const { t } = useTranslation();

  return (
    <SuiBox component="section" py={{ xs: 6, md: 12 }} mb={10}>
      <Container>
        <SuiBox
          alignItems="center"
          mb={6}
          sx={{ textAlign: "center", mx: "auto" }}
        >
          <SuiTypography variant="h2" fontWeight="bold" mb={1}>
            {t("Our services")}
          </SuiTypography>
        </SuiBox>
        <Grid container item xs={12} justifyContent="center">
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              ml: { xs: 0, md: "auto" },
              mr: { xs: 0, md: 6 },
              mb: { xs: 4, md: 0 },
            }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <CdxSimpleInfoCard
                color="dark"
                icon="computer"
                title={t("Online application")}
                description={t(
                  "We develop various types of online applications to support your work."
                )}
              />
              <CdxSimpleInfoCard
                color="dark"
                icon="rocket_launch"
                title={t("Customised application")}
                description={t(
                  "We can assist you about integrating new functions into your current system, or develop the customised application required by you."
                )}
              />
            </Stack>
          </Grid>
          <Grid
            item
            xs={12}
            md={4}
            sx={{
              mr: { xs: 0, md: "auto" },
              ml: { xs: 0, md: 6 },
              mb: { xs: 4, md: 0 },
            }}
          >
            <Stack spacing={{ xs: 4, md: 8 }}>
              <CdxSimpleInfoCard
                color="dark"
                icon="insights"
                title={t("Consulting services")}
                description={t(
                  "We can provide you with recommendations on business topics, especially finance, accounting and tax related."
                )}
              />
            </Stack>
          </Grid>
        </Grid>
      </Container>
    </SuiBox>
  );
};

export default OurServices;
