import { COOKIE_PRIVACY_CONSENT } from "settings/cookies";
import { BASE_COOKIE_URL } from "settings";

import Cookies from "js-cookie";

const getConsent = () => {
  let consent =
    Cookies.get(COOKIE_PRIVACY_CONSENT, { domain: BASE_COOKIE_URL }) || "{}";
  try {
    consent = JSON.parse(consent);
  } catch {
    consent = { necessary: false, performance: false };
  }
  return consent;
};

export default getConsent;
