import { Grid, Icon } from "@mui/material";

import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";

import changeLanguage from "app/utils/changeLanguage";

import { useTranslation } from "react-i18next";

import { LANGUAGES_OPTIONS } from "settings/i18n";

const CdxChangeLanguage = ({ color = "dark" }) => {
  const currentLanguage = useTranslation().i18n.language;

  const clickToChangeLanguage = () => {
    const optionsLength = LANGUAGES_OPTIONS.length;
    const currentIndex =
      LANGUAGES_OPTIONS.findIndex((e) => e.value === currentLanguage) || 0;
    const nextIndex = currentIndex + 1 >= optionsLength ? 0 : currentIndex + 1;
    const lang = LANGUAGES_OPTIONS.at(nextIndex).value;

    changeLanguage(lang);
  };

  return (
    <Grid
      container
      spacing={0.6}
      alignItems="center"
      sx={{ cursor: "pointer", userSelect: "none" }}
      onClick={clickToChangeLanguage}
    >
      <Grid item>
        <SuiBox display="flex" alignItems="center" color={color}>
          <Icon>language</Icon>
        </SuiBox>
      </Grid>
      <Grid item>
        <SuiBox lineHeight={0.7}>
          <SuiTypography
            variant="caption"
            color={color}
            textTransform="uppercase"
          >
            {currentLanguage || ""}
          </SuiTypography>
        </SuiBox>
      </Grid>
    </Grid>
  );
};

export default CdxChangeLanguage;
