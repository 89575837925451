import { Fragment } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";

import PrivacyConsent from "layouts/privacy-consent";

import AppRoute from "AppRoute";

const App = () => {
  return (
    <Fragment>
      <BrowserRouter>
        <AppRoute />
        <PrivacyConsent />
      </BrowserRouter>
    </Fragment>
  );
};

export default App;
