import { useTranslation } from "react-i18next";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
// import Stack from "@mui/material/Stack";

// Material Kit 2 PRO React components
import SuiBox from "components/sui/SuiBox";
import SuiTypography from "components/sui/SuiTypography";
import SuiButton from "components/sui/SuiButton";

// Home page sections
import OurServices from "pages/home/sections/OurServices";
import OnlineApplication from "pages/home/sections/online-application";
import OurExperiences from "pages/home/sections/OurExperiences";

// Images
import bgImage from "assets/images/landing-page1.jpg";
import bgContactUs from "assets/images/bg-contact-us.jpg";

import StandardLayout from "layouts/standard-layout";

const Home = () => {
  const { t } = useTranslation();

  return (
    <StandardLayout>
      <SuiBox
        minHeight="75vh"
        width="100%"
        sx={{
          backgroundImage: ({
            functions: { linearGradient, rgba },
            palette: { gradients },
          }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.1),
              rgba(gradients.dark.state, 0.1)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
          userSelect: "none",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            md={10}
            lg={9}
            xl={7}
            justifyContent={{ xs: "center", md: "start" }}
            sx={{ textAlign: { xs: "center", md: "left" } }}
          >
            <SuiTypography
              variant="h1"
              color="white"
              sx={({ breakpoints, typography: { size } }) => ({
                [breakpoints.down("md")]: {
                  fontSize: size["3xl"],
                },
              })}
            >
              {t("Your Perfect Finance and Accounting Assistance")}
            </SuiTypography>
            <SuiTypography
              variant="body1"
              color="white"
              mt={1}
              pr={{ md: 12, lg: 24, xl: 32 }}
              opacity={0.8}
            >
              {t(
                "We are finance and accounting specialised consultant and application developer."
              )}
            </SuiTypography>
            {/* <Stack direction="row" spacing={1} mt={6} mb={3}>
              <SuiButton variant="gradient" color="info">
                get started
              </SuiButton>
              <SuiButton variant="text" color="white">
                read more
              </SuiButton>
            </Stack> */}
          </Grid>
        </Container>
      </SuiBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: -8,
          mb: 4,
          backgroundColor: ({ palette: { white }, functions: { rgba } }) =>
            rgba(white.main, 0.8),
          backdropFilter: "saturate(200%) blur(30px)",
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
          userSelect: "none",
        }}
      >
        <OurServices />
        <OnlineApplication />
        <OurExperiences />
        <Container>
          <SuiBox
            borderRadius="xl"
            my={12}
            p={6}
            sx={{
              backgroundImage: ({
                functions: { linearGradient, rgba },
                palette: { gradients },
              }) =>
                `${linearGradient(
                  rgba(gradients.dark.main, 0.2),
                  rgba(gradients.dark.state, 0.2)
                )}, url(${bgContactUs})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              display: "grid",
              placeItems: "center",
            }}
          >
            <Grid container spacing={3} alignItems="center">
              <Grid item xs={12} md>
                <SuiTypography variant="h5" color="white" fontWeight="bold">
                  {t("Would like to know more or interesting in our services?")}
                </SuiTypography>
              </Grid>
              <Grid item xs={12} md="auto" sx={{ ml: "auto" }}>
                <SuiBox width="12rem" ml="auto">
                  <SuiButton
                    component="a"
                    href="mailto:info@codexlab.co"
                    variant="gradient"
                    color="primary"
                    fullWidth
                    sx={{ boxShadow: "none" }}
                  >
                    {t("contact us")}
                  </SuiButton>
                </SuiBox>
              </Grid>
            </Grid>
          </SuiBox>
        </Container>
      </Card>
    </StandardLayout>
  );
};

export default Home;
